.section-title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 127.3%;
    text-align: center;
    text-transform: uppercase;
}

.section-title-small {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 133%;
}

.speaker-name {
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 133%;
    text-align: center;

    @media (max-width: 48em) {
        font-size: 1.5rem;
    }
}