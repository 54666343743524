@import "../functions";

.body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url(../../images/hero.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    background-size: contain;
    padding: 88px 0 50px;

    @media (max-width: 72em) {
        padding: 30px 0 30px;
    }

    @media (max-width: 64em) {
        background-image: none;
        padding: 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 0 0 703px;
    position: relative;
    z-index: 1;

    @media (max-width: 64em) {
        flex: 1;
        align-items: center;
        gap: 0;
    }
}

.image {
    display: none;

    @media (max-width: 64em) {
        display: block;
        filter: drop-shadow(0px 4px 15px rgba(61, 61, 61, 0.17));
    }

    img {
        display: flex;
    }
}

.subtitle {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 127.3%;
    letter-spacing: 0.11em;
    text-transform: uppercase;
    color: var(--subtitle);

    @media (max-width: 64em) {
        margin-bottom: 8px;
    }

    @media (max-width: 48em) {
        font-size: 0.875rem;
    }
}

.date {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 18px;
    background: var(--txt-gradient);
    border-radius: 28.8669px;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5;
    color: #FFFFFF;

    p::first-letter {
        margin-right: 5px;
    }

    @media (max-width: 64em) {
        margin-bottom: 24px;
    }

    @media (max-width: 48em) {
        font-size: 0.75rem;
        padding: 8px 22px;
    }
}

.title {
    font-weight: bold;
    font-size: 3rem;
    line-height: 127.3%;
    text-transform: uppercase;
    text-shadow: stroke(3, var(--light));

    @media (max-width: 64em) {
        text-align: center;
        margin-bottom: 15px;
    }

    @media (max-width: 48em) {
        font-size: 2.5rem;
    }

    @media (max-width: 30em) {
        font-size: 1.5rem;
    }

    @media (max-width: 25em) {
        width: 285px;
    }
}

.text {
    line-height: 1.36;
    background: var(--txt-gradient);
    background-clip: text;
    color: transparent;
    margin-bottom: -12px;

    @media (max-width: 64em) {
        text-align: center;
        order: 2;
        margin-bottom: 0;
    }

    @media (max-width: 48em) {
        font-size: 0.75rem;
        width: 177px;
    }
}

.button {

    text-decoration: none;
    background: var(--btn-gradient);
    box-shadow: var(--btn-shadow);
    border-radius: var(--btn-radius);
    width: 299px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    line-height: 127.3%;
    letter-spacing: 0.04em;
    color: var(--light);

    @media (max-width: 64em) {
        text-align: center;
        order: 1;
        margin-top: -30px;
        position: relative;
        margin-bottom: 20px;
        box-shadow: 0 0 0 14px var(--light);
    }

    @media (max-width: 48em) {
        width: 158px;
        height: 51px;
        margin-top: -26px;
    }
}