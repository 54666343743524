.badge {
    background-image: url(../../images/logo-small.png);
    background-repeat: no-repeat;
    background-size: 200px;
    background-color: var(--light);
    width: 268px;
    height: 50px;
    border-radius: 67px;
    background-position-x: center;
    background-position-y: center;
    box-shadow: 0 0 0 6px var(--accent);
    position: relative;
    margin-top: -30px;

    @media (max-width: 48em) {
        margin-top: -15px;
        width: 146px;
        height: 28px;
        background-size: 110px;
    }
}