.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;

    @media (max-width: 48em) {
        gap: 24px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}

.logo{
    width: 457px;
    height: 66px;

    @media (max-width: 48em) {
        width: 180px;
        height: 26px;
    }
}

.text{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.125rem;
    line-height: 2.1;

    @media (max-width: 48em) {
        font-size: 0.875rem;
        line-height: 1.86;
    }
}