
.body {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 62em) {
        flex-direction: column;
        gap: 45px;
    }
}

.content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (max-width: 62em) {
        width: 100%;
    }

    @media (max-width: 48em) {
        gap: 24px;
    }
}

.list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-left: 24px;
    align-self: flex-end;

    @media (max-width: 62em) {
        align-self: flex-start;
    }

    &__item {
        position: relative;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 1.33;
        display: flex;
        gap: 38px;

        @media (max-width: 48em) {
            gap: 24px;
            font-size: 1.125rem;
        }

        &::before {
            content: "";
            display: block;
            flex: 0 0 12px;
            margin-top: 12px;
            height: 12px;
            background: var(--txt-gradient);
            border-radius: var(--btn-radius);

            @media (max-width: 48em) {
                margin-top: 6px;
            }
        }

    }
}

.image {
    flex: 0 0 518px;

    @media (max-width: 62em) {
        flex: 1;
    }
}