
.body {
    display: flex;
    flex-direction: column;
    gap: 70px;

    @media (max-width: 48em) {
        gap: 25px;
    }
}

.list {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    gap: 24px;

    @media (max-width: 48em) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
    }

    &__item {
        flex: 0 1 351px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        @media (max-width: 48em) {
            flex: 1;
            max-width: 351px;
            gap: 18px;
        }

        @media (max-width: 25em) {
            width: 212px;
        }
    }

    .image {
        width: 51%;
        display: flex;
        justify-content: center;

        @media (max-width: 30em) {
            width: 40%;
        }
    }

    .text {
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.33;
        text-align: center;
        letter-spacing: 0.04em;
    }
}