.header{
    padding: 89px 0 32px;

    @media (max-width: 48em) {
        padding: 30px 0 47px;
    }
}

.logo{
    width: 207px;

    @media (max-width: 48em) {
        width: 138px;
    }
}