*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Red Hat Display', sans-serif;
  color: var(--accent);
  font-size: var(--main);
  background-color: var(--light);
  min-width: 375px;
}

img {
  max-width: 100%;
  height: auto;
}

:root {
  // containers

  --c-width: 1350px;
  --c-padding: 16px;

  // font sizes

  --main: 0.875rem;

  // colors

  --accent: #4C246D;
  --light: #FFF;
  --subtitle: #D5429C;

  // filters

  --filter: drop-shadow(0px 4px 15px rgba(61, 61, 61, 0.17));

  // gradients

  --btn-gradient: linear-gradient(100.73deg, #8A246D 15.61%, #4F246D 107.37%);

  --txt-gradient: linear-gradient(93.66deg, #EA4880 9.88%, #C13DB5 103.86%);

  // shadows

  --btn-shadow: 0px 2px 16px rgba(113, 36, 109, 0.33);

  // border-radius

  --btn-radius: 50px;
}