.footer {
    padding-top: 60px;
    padding-bottom: 124px;
    display: flex;
    justify-content: center;

    @media (max-width: 48em) {
        padding-bottom: 102px;
    }

    .button {

        text-decoration: none;
        background: var(--btn-gradient);
        box-shadow: var(--btn-shadow);
        border-radius: var(--btn-radius);
        width: 263px;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.125rem;
        line-height: 127.3%;
        letter-spacing: 0.04em;
        color: var(--light);
    }
}