.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;

    @media (max-width: 48em) {
        gap: 24px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 645px;
    align-items: center;
    @media (max-width: 48em) {
        gap: 14px;
    }
}

.image {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 48em) {
        width: 175px;
        margin-bottom: 10px;
    }

    img {
        display: flex;
        box-shadow: 0 0 0 6px var(--accent);
        border-radius: 50%;

        @media (max-width: 48em) {
            box-shadow: 0 0 0 5px var(--accent);
        }
    }
}

.text {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 2.1;
    text-align: center;

    @media (max-width: 48em) {
        font-size: 0.875rem;
        line-height: 1.87;
    }
}